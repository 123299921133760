import moment from 'moment';
import { ColorBatch, TableActions } from 'components';
import {
  BOOKING_STATUS_COLOR,
  BOOKING_STATUSES_INVERSE,
  TRANSACTION_STATUS_COLOR,
  TRANSACTION_STATUS_TYPE,
} from 'enums';
import { linkButton, bookingStyles, statusesContainer } from './styles';

export const allBookingsColumns = (getText, convertedToLocalCurrencyPrice, queryParams) => [
  {
    name: getText('bookingId'),
    render: ({ id, createdDate }) => (
      <div css={bookingStyles}>
        <p>
          <b className="black-styles">{getText('bookingId')}: </b>
          {id}
        </p>
        <p className="gray-styles">
          <b className="black-styles">{getText('dateOfCreation')}: </b>
          {moment(createdDate).format('DD-MM-YYYY')}
        </p>
      </div>
    ),
    width: '210px',
  },
  {
    name: getText('checkInOutDates'),
    render: ({ checkIn, checkOut }) => (
      <p>{`${moment(checkIn).format('DD-MMM')} / ${moment(checkOut).format('DD-MMM')}`}</p>
    ),
    width: '100px',
  },
  {
    name: getText('property'),
    render: ({ hotelName, hotelId, propertyType }) => (
      <div css={bookingStyles}>
        <p>
          <b className="black-styles">{getText('hotelId')}: </b>
          {hotelId}
        </p>
        <p>
          <b className="black-styles">{getText('hotelName')}: </b>
          {hotelName}
        </p>
        {propertyType && (
          <p className="gray-styles">
            <b className="black-styles">{getText('propertyType')}: </b>
            {propertyType}
          </p>
        )}
      </div>
    ),
  },
  {
    name: getText('user'),
    render: ({ user }) => (
      <div css={bookingStyles}>
        <p className="blue-styles">{`${user?.firstName} ${user?.lastName}`}</p>
        <p className="gray-styles">{user?.email}</p>
        <p className="gray-styles">{user?.userId}</p>
      </div>
    ),
  },
  {
    name: getText('statuses'),
    render: ({ amountTransactionStatus, cryptoTransactionStatus, status }) => {
      return (
        <div css={statusesContainer}>
          <div>
            <b className="black-styles">{getText('booking')}: </b>
            <ColorBatch withoutStartCase type={BOOKING_STATUS_COLOR[status]?.color}>
              {getText(BOOKING_STATUSES_INVERSE[status])}
            </ColorBatch>
          </div>
          {cryptoTransactionStatus && (
            <div>
              <b className="black-styles">{getText('crypto')}: </b>
              <ColorBatch withoutStartCase type={TRANSACTION_STATUS_COLOR[cryptoTransactionStatus]?.color}>
                {getText(TRANSACTION_STATUS_TYPE[cryptoTransactionStatus])}
              </ColorBatch>
            </div>
          )}
          <div>
            <b className="black-styles">{getText('fiat')}: </b>
            <ColorBatch withoutStartCase type={TRANSACTION_STATUS_COLOR[amountTransactionStatus]?.color}>
              {getText(TRANSACTION_STATUS_TYPE[amountTransactionStatus])}
            </ColorBatch>
          </div>
        </div>
      );
    },
    width: '250px',
  },
  {
    name: getText('price'),
    render: ({ fiatPrice, cryptoPrice }) => (
      <p className="price">{convertedToLocalCurrencyPrice(fiatPrice, cryptoPrice)}</p>
    ),
  },
  {
    name: getText('guestsCount'),
    value: 'guestsCount',
    width: '100px',
  },
  {
    name: 'Actions',
    render: (booking) => (
      <TableActions
        mainAction={{
          type: 'link',
          linkTo: { pathname: `/bookings/details/${booking?.id}`, state: { booking, queryParams } },
          children: getText('viewDetails'),
          css: linkButton,
        }}
      />
    ),
    width: '110px',
  },
];
