import { useTranslations } from '@veraio/strank';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { BookingsFilterBar, PriceLabel, Table, useUrlParams } from 'components';
import { DEFAULT_CURRENCY } from 'enums';
import { getAllBookings } from 'services';
import { allBookingsColumns } from './config';
import { bookingsContainer } from './styles';

const filterKeys = {
  bookingId: 'bookingId',
  creationDate: 'creationDate',
  checkIn: 'checkIn',
  checkOut: 'checkOut',
  fiatPriceFrom: 'fiatPriceFrom',
  fiatPriceTo: 'fiatPriceTo',
  cryptoPriceFrom: 'cryptoPriceFrom',
  cryptoPriceTo: 'cryptoPriceTo',
  status: 'status',
  userId: 'userId',
  email: 'email',
};

const BookingsPage = () => {
  const { getText } = useTranslations();
  const { queryParams } = useUrlParams();

  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);

  const convertedToLocalCurrencyPrice = (fiatPrice, cryptoPrice) =>
    fiatPrice && (
      <PriceLabel
        fiat={Number(fiatPrice)}
        crypto={Number(cryptoPrice) ?? 0}
        fiatOriginCurrency={DEFAULT_CURRENCY.code}
        fiatDesiredCurrency={selectedCurrency?.code}
        fiatOnly={false}
      />
    );

  return (
    <div css={bookingsContainer}>
      <h3 className="title">{getText('bookings')}</h3>
      <Table
        hasPagination
        getDataKey="results"
        emptyMessage={getText('thereAreNoBookings')}
        getDataMethod={getAllBookings}
        columns={allBookingsColumns(getText, convertedToLocalCurrencyPrice, queryParams)}
        filterBar={BookingsFilterBar}
        filterKeys={filterKeys}
      />
    </div>
  );
};

export default BookingsPage;
